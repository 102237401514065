export const defaultState = {
  contactsFilters: {
    search: null,
    status: null
  },
  contactsPagination: {
    currentPage: 1,
    itemsPerPage: 10
  },
  contactsSort: {
    column: 'Name',
    desc: false
  }
};

const state = () => defaultState;

export default state;

const phoneRegex = /^(\d-)?\d{3}-\d{3}-\d{4}$/;

export default {
  getMessage() {
    return 'Invalid phone number';
  },
  validate(value) {
    if (value === null) {
      return true;
    }
    return phoneRegex.test(value);
  }
};

import Vue from 'vue';

Vue.directive('click-outside', {
  bind: function(element, binding, vnode) {
    element.clickOutside = event => {
      if (element !== event.target && !element.contains(event.target)) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', element.clickOutside);
  },
  unbind: function(element) {
    document.body.removeEventListener('click', element.clickOutside);
  }
});

export default {
  setContactsFilters({ commit }, value) {
    commit('setContactsFilters', value);
  },
  setContactsPagination({ commit }, value) {
    commit('setContactsPagination', value);
  },
  setContactsSort({ commit }, value) {
    commit('setContactsSort', value);
  }
};

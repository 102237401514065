export const defaultState = {
  agencyCodesFilters: {
    product: null,
    search: null
  },
  agencyCodesPagination: {
    currentPage: 1,
    itemsPerPage: 10
  },
  agencyCodesSort: {
    column: 'Code',
    desc: false
  }
};

const state = () => defaultState;

export default state;

export const defaultState = {
  programsFilters: {
    activeFromAfter: null,
    activeToBefore: null,
    search: null
  },
  programsPagination: {
    currentPage: 1,
    itemsPerPage: 10
  },
  programsSort: {
    column: 'Name',
    desc: false
  }
};

const state = () => defaultState;

export default state;

export default {
  setBannersFilters({ commit }, value) {
    commit('setBannersFilters', value);
  },
  setBannersPagination({ commit }, value) {
    commit('setBannersPagination', value);
  },
  setBannersSort({ commit }, value) {
    commit('setBannersSort', value);
  }
};

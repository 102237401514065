//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import authenticationMixins from '~/mixins/authentication';

export default {
  computed: {
    firstName() {
      const username = this.$username();
      if (!username) {
        return null;
      }

      return username.split(' ')[0];
    },
    lastName() {
      const username = this.$username();
      if (!username) {
        return null;
      }

      const splitted = username.split(' ');
      splitted.splice(0, 1);

      return splitted.join(' ');
    }
  },
  mixins: [authenticationMixins]
};

import { onError } from 'apollo-link-error';

export default ({ $config, i18n, $toast }) => {
  const link = onError(({ response }) => {
    if (response && response.errors.length) {
      const { errors, extensions } = response;

      const error = errors[0];
      const field = extensions?.value?.field;

      if (error.path?.includes('createAgencyCode') || error.path?.includes('updateAgencyCode')) {
        if (error.extensions.code === 'CONFLICT' && field) {
          $toast.error(i18n.t(`agencyCodes.errors.${field.charAt(0).toLowerCase() + field.slice(1)}`));
          return;
        }
      }

      if (error.path?.includes('createBanner') || error.path?.includes('updateBanner')) {
        if (error.extensions.code === 'BANNER_TYPE') {
          $toast.error(i18n.t('banners.errors.bannerType'));
          return;
        }

        if (error.extensions.code === 'CONFLICT' && field) {
          $toast.error(i18n.t(`banners.errors.${field.charAt(0).toLowerCase() + field.slice(1)}`));
          return;
        }
      }

      if (error.path?.includes('createBroker') || error.path?.includes('updateBroker')) {
        if (error.extensions.code === 'CONFLICT' && field) {
          $toast.error(i18n.t(`brokers.errors.${field.charAt(0).toLowerCase() + field.slice(1)}`));
          return;
        }
      }

      if (error.path?.includes('updateBroker')) {
        if (error.extensions.code === 'BANNER_TYPE') {
          $toast.error(i18n.t('brokers.errors.bannerType'));
          return;
        }
      }
    }
  });

  return {
    httpEndpoint: $config.graphQlEndpoint,
    link
  };
};

export default {
  setAgencyCodesFilters({ commit }, value) {
    commit('setAgencyCodesFilters', value);
  },
  setAgencyCodesPagination({ commit }, value) {
    commit('setAgencyCodesPagination', value);
  },
  setAgencyCodesSort({ commit }, value) {
    commit('setAgencyCodesSort', value);
  }
};

export const defaultState = {
  brokersFilters: {
    banner: null,
    search: null,
    role: null,
    status: null
  },
  brokersPagination: {
    currentPage: 1,
    itemsPerPage: 10
  },
  brokersSort: {
    column: 'Name',
    desc: false
  }
};

const state = () => defaultState;

export default state;

export const Footer = () => import('../../components/global/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/global/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const PageHeader = () => import('../../components/global/PageHeader.vue' /* webpackChunkName: "components/page-header" */).then(c => wrapFunctional(c.default || c))
export const PageLoader = () => import('../../components/global/PageLoader.vue' /* webpackChunkName: "components/page-loader" */).then(c => wrapFunctional(c.default || c))
export const AgencyCodesForm = () => import('../../components/agencyCodes/form.vue' /* webpackChunkName: "components/agency-codes-form" */).then(c => wrapFunctional(c.default || c))
export const AgencyCodesHeader = () => import('../../components/agencyCodes/header.vue' /* webpackChunkName: "components/agency-codes-header" */).then(c => wrapFunctional(c.default || c))
export const BannersAgencyCodes = () => import('../../components/banners/agencyCodes.vue' /* webpackChunkName: "components/banners-agency-codes" */).then(c => wrapFunctional(c.default || c))
export const BannersBrokers = () => import('../../components/banners/brokers.vue' /* webpackChunkName: "components/banners-brokers" */).then(c => wrapFunctional(c.default || c))
export const BannersContacts = () => import('../../components/banners/contacts.vue' /* webpackChunkName: "components/banners-contacts" */).then(c => wrapFunctional(c.default || c))
export const BannersFlexs = () => import('../../components/banners/flexs.vue' /* webpackChunkName: "components/banners-flexs" */).then(c => wrapFunctional(c.default || c))
export const BannersForm = () => import('../../components/banners/form.vue' /* webpackChunkName: "components/banners-form" */).then(c => wrapFunctional(c.default || c))
export const BannersHeader = () => import('../../components/banners/header.vue' /* webpackChunkName: "components/banners-header" */).then(c => wrapFunctional(c.default || c))
export const BannersPrograms = () => import('../../components/banners/programs.vue' /* webpackChunkName: "components/banners-programs" */).then(c => wrapFunctional(c.default || c))
export const BrokersAgencyCodes = () => import('../../components/brokers/agencyCodes.vue' /* webpackChunkName: "components/brokers-agency-codes" */).then(c => wrapFunctional(c.default || c))
export const BrokersForm = () => import('../../components/brokers/form.vue' /* webpackChunkName: "components/brokers-form" */).then(c => wrapFunctional(c.default || c))
export const BrokersHeader = () => import('../../components/brokers/header.vue' /* webpackChunkName: "components/brokers-header" */).then(c => wrapFunctional(c.default || c))
export const BrokersPrograms = () => import('../../components/brokers/programs.vue' /* webpackChunkName: "components/brokers-programs" */).then(c => wrapFunctional(c.default || c))
export const ContactsForm = () => import('../../components/contacts/form.vue' /* webpackChunkName: "components/contacts-form" */).then(c => wrapFunctional(c.default || c))
export const ContactsHeader = () => import('../../components/contacts/header.vue' /* webpackChunkName: "components/contacts-header" */).then(c => wrapFunctional(c.default || c))
export const ProgramsAgencyCodes = () => import('../../components/programs/agencyCodes.vue' /* webpackChunkName: "components/programs-agency-codes" */).then(c => wrapFunctional(c.default || c))
export const ProgramsBannerAssociation = () => import('../../components/programs/bannerAssociation.vue' /* webpackChunkName: "components/programs-banner-association" */).then(c => wrapFunctional(c.default || c))
export const ProgramsBanners = () => import('../../components/programs/banners.vue' /* webpackChunkName: "components/programs-banners" */).then(c => wrapFunctional(c.default || c))
export const ProgramsFlexs = () => import('../../components/programs/flexs.vue' /* webpackChunkName: "components/programs-flexs" */).then(c => wrapFunctional(c.default || c))
export const ProgramsForm = () => import('../../components/programs/form.vue' /* webpackChunkName: "components/programs-form" */).then(c => wrapFunctional(c.default || c))
export const ProgramsHeader = () => import('../../components/programs/header.vue' /* webpackChunkName: "components/programs-header" */).then(c => wrapFunctional(c.default || c))
export const ProgramsStatus = () => import('../../components/programs/status.vue' /* webpackChunkName: "components/programs-status" */).then(c => wrapFunctional(c.default || c))
export const UiActivityStatus = () => import('../../components/ui/ActivityStatus.vue' /* webpackChunkName: "components/ui-activity-status" */).then(c => wrapFunctional(c.default || c))
export const UiBox = () => import('../../components/ui/Box.vue' /* webpackChunkName: "components/ui-box" */).then(c => wrapFunctional(c.default || c))
export const UiCalendar = () => import('../../components/ui/Calendar.vue' /* webpackChunkName: "components/ui-calendar" */).then(c => wrapFunctional(c.default || c))
export const UiConfirmationModal = () => import('../../components/ui/ConfirmationModal.vue' /* webpackChunkName: "components/ui-confirmation-modal" */).then(c => wrapFunctional(c.default || c))
export const UiDrawer = () => import('../../components/ui/Drawer.vue' /* webpackChunkName: "components/ui-drawer" */).then(c => wrapFunctional(c.default || c))
export const UiDropdown = () => import('../../components/ui/Dropdown.vue' /* webpackChunkName: "components/ui-dropdown" */).then(c => wrapFunctional(c.default || c))
export const UiMessageBox = () => import('../../components/ui/MessageBox.vue' /* webpackChunkName: "components/ui-message-box" */).then(c => wrapFunctional(c.default || c))
export const UiPageLoading = () => import('../../components/ui/PageLoading.vue' /* webpackChunkName: "components/ui-page-loading" */).then(c => wrapFunctional(c.default || c))
export const UiPagination = () => import('../../components/ui/Pagination.vue' /* webpackChunkName: "components/ui-pagination" */).then(c => wrapFunctional(c.default || c))
export const UiSpinner = () => import('../../components/ui/Spinner.vue' /* webpackChunkName: "components/ui-spinner" */).then(c => wrapFunctional(c.default || c))
export const UiTable = () => import('../../components/ui/Table.vue' /* webpackChunkName: "components/ui-table" */).then(c => wrapFunctional(c.default || c))
export const UiTabs = () => import('../../components/ui/Tabs.vue' /* webpackChunkName: "components/ui-tabs" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}

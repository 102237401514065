import jwt_decode from 'jwt-decode';

export default ({ app }, inject) => {
  inject('isLoggedIn', () => {
    return Boolean(app.$auth.$storage.getUniversal('_token.social'));
  });

  inject('isTokenExpired', () => {
    const token = app.$auth.$storage.getUniversal('_token.social');
    const decodedBearer = jwt_decode(token);
    return decodedBearer.exp * 1000 <= new Date().getTime();
  });

  inject('login', () => {
    app.$auth.loginWith('social');
  });

  inject('username', () => {
    const token = app.$auth.$storage.getUniversal('_token.social');

    if (!token) {
      return null;
    }

    const decodedBearer = jwt_decode(token);
    return decodedBearer.name;
  });
};

import { extend } from 'vee-validate';
import decimal from '@/rules/decimal';
import { email, integer, max, max_value, min_value, regex, required } from 'vee-validate/dist/rules';
import nestedRequired from '@/rules/nestedRequired';
import phone from '@/rules/phone';
import postalCode from '@/rules/postalCode';

extend('decimal', decimal);
extend('email', email);
extend('integer', integer);
extend('max', max);
extend('maxValue', max_value);
extend('minValue', min_value);
extend('nestedRequired', nestedRequired);
extend('phone', phone);
extend('postalCode', postalCode);
extend('regex', regex);
extend('required', required);

export default {
  mounted() {
    this.validateAuthentication();
  },
  methods: {
    logout() {
      this.$auth.reset();
      window.location.href = `${this.$auth.strategies.social.options.endpoints.logout}?clientId=${this.$auth.strategies.social.options.clientId}&post_logout_redirect_uri=${this.$auth.strategies.social.options.logoutRedirectUri}`;
    },
    validateAuthentication() {
      if (this.$route.path.includes('auth')) {
        return;
      }

      if (!this.$isLoggedIn()) {
        this.$login();
        return;
      }

      if (this.$isTokenExpired()) {
        this.logout();
      }
    }
  }
};

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4bc57b46 = () => interopDefault(import('../pages/agency-codes/index.vue' /* webpackChunkName: "pages/agency-codes/index" */))
const _1044c727 = () => interopDefault(import('../pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _4d22c23e = () => interopDefault(import('../pages/banners/index.vue' /* webpackChunkName: "pages/banners/index" */))
const _07d6e91e = () => interopDefault(import('../pages/brokers/index.vue' /* webpackChunkName: "pages/brokers/index" */))
const _32f45e12 = () => interopDefault(import('../pages/contacts/index.vue' /* webpackChunkName: "pages/contacts/index" */))
const _482400f4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5ea0bd2e = () => interopDefault(import('../pages/programs/index.vue' /* webpackChunkName: "pages/programs/index" */))
const _dd8157fe = () => interopDefault(import('../pages/agency-codes/create.vue' /* webpackChunkName: "pages/agency-codes/create" */))
const _7ee9d040 = () => interopDefault(import('../pages/banners/create.vue' /* webpackChunkName: "pages/banners/create" */))
const _a39da526 = () => interopDefault(import('../pages/brokers/create.vue' /* webpackChunkName: "pages/brokers/create" */))
const _534baeec = () => interopDefault(import('../pages/contacts/create.vue' /* webpackChunkName: "pages/contacts/create" */))
const _c5a99960 = () => interopDefault(import('../pages/programs/create.vue' /* webpackChunkName: "pages/programs/create" */))
const _ac4d7850 = () => interopDefault(import('../pages/agency-codes/_slug/index.vue' /* webpackChunkName: "pages/agency-codes/_slug/index" */))
const _8a44d20e = () => interopDefault(import('../pages/banners/_slug/index.vue' /* webpackChunkName: "pages/banners/_slug/index" */))
const _011b2428 = () => interopDefault(import('../pages/brokers/_slug/index.vue' /* webpackChunkName: "pages/brokers/_slug/index" */))
const _747803cd = () => interopDefault(import('../pages/contacts/_slug/index.vue' /* webpackChunkName: "pages/contacts/_slug/index" */))
const _684f9de9 = () => interopDefault(import('../pages/programs/_slug/index.vue' /* webpackChunkName: "pages/programs/_slug/index" */))
const _e081f718 = () => interopDefault(import('../pages/agency-codes/_slug/edit.vue' /* webpackChunkName: "pages/agency-codes/_slug/edit" */))
const _6f434933 = () => interopDefault(import('../pages/banners/_slug/edit.vue' /* webpackChunkName: "pages/banners/_slug/edit" */))
const _77e37040 = () => interopDefault(import('../pages/brokers/_slug/edit.vue' /* webpackChunkName: "pages/brokers/_slug/edit" */))
const _6cfdf1df = () => interopDefault(import('../pages/contacts/_slug/edit.vue' /* webpackChunkName: "pages/contacts/_slug/edit" */))
const _0e06b77a = () => interopDefault(import('../pages/programs/_slug/edit.vue' /* webpackChunkName: "pages/programs/_slug/edit" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/agency-codes",
    component: _4bc57b46,
    name: "agency-codes___fr-CA"
  }, {
    path: "/auth",
    component: _1044c727,
    name: "auth___fr-CA"
  }, {
    path: "/banners",
    component: _4d22c23e,
    name: "banners___fr-CA"
  }, {
    path: "/brokers",
    component: _07d6e91e,
    name: "brokers___fr-CA"
  }, {
    path: "/contacts",
    component: _32f45e12,
    name: "contacts___fr-CA"
  }, {
    path: "/en-CA",
    component: _482400f4,
    name: "index___en-CA"
  }, {
    path: "/programs",
    component: _5ea0bd2e,
    name: "programs___fr-CA"
  }, {
    path: "/agency-codes/create",
    component: _dd8157fe,
    name: "agency-codes-create___fr-CA"
  }, {
    path: "/banners/create",
    component: _7ee9d040,
    name: "banners-create___fr-CA"
  }, {
    path: "/brokers/create",
    component: _a39da526,
    name: "brokers-create___fr-CA"
  }, {
    path: "/contacts/create",
    component: _534baeec,
    name: "contacts-create___fr-CA"
  }, {
    path: "/en-CA/agency-codes",
    component: _4bc57b46,
    name: "agency-codes___en-CA"
  }, {
    path: "/en-CA/auth",
    component: _1044c727,
    name: "auth___en-CA"
  }, {
    path: "/en-CA/banners",
    component: _4d22c23e,
    name: "banners___en-CA"
  }, {
    path: "/en-CA/brokers",
    component: _07d6e91e,
    name: "brokers___en-CA"
  }, {
    path: "/en-CA/contacts",
    component: _32f45e12,
    name: "contacts___en-CA"
  }, {
    path: "/en-CA/programs",
    component: _5ea0bd2e,
    name: "programs___en-CA"
  }, {
    path: "/programs/create",
    component: _c5a99960,
    name: "programs-create___fr-CA"
  }, {
    path: "/en-CA/agency-codes/create",
    component: _dd8157fe,
    name: "agency-codes-create___en-CA"
  }, {
    path: "/en-CA/banners/create",
    component: _7ee9d040,
    name: "banners-create___en-CA"
  }, {
    path: "/en-CA/brokers/create",
    component: _a39da526,
    name: "brokers-create___en-CA"
  }, {
    path: "/en-CA/contacts/create",
    component: _534baeec,
    name: "contacts-create___en-CA"
  }, {
    path: "/en-CA/programs/create",
    component: _c5a99960,
    name: "programs-create___en-CA"
  }, {
    path: "/en-CA/agency-codes/:slug",
    component: _ac4d7850,
    name: "agency-codes-slug___en-CA"
  }, {
    path: "/en-CA/banners/:slug",
    component: _8a44d20e,
    name: "banners-slug___en-CA"
  }, {
    path: "/en-CA/brokers/:slug",
    component: _011b2428,
    name: "brokers-slug___en-CA"
  }, {
    path: "/en-CA/contacts/:slug",
    component: _747803cd,
    name: "contacts-slug___en-CA"
  }, {
    path: "/en-CA/programs/:slug",
    component: _684f9de9,
    name: "programs-slug___en-CA"
  }, {
    path: "/en-CA/agency-codes/:slug?/edit",
    component: _e081f718,
    name: "agency-codes-slug-edit___en-CA"
  }, {
    path: "/en-CA/banners/:slug/edit",
    component: _6f434933,
    name: "banners-slug-edit___en-CA"
  }, {
    path: "/en-CA/brokers/:slug/edit",
    component: _77e37040,
    name: "brokers-slug-edit___en-CA"
  }, {
    path: "/en-CA/contacts/:slug/edit",
    component: _6cfdf1df,
    name: "contacts-slug-edit___en-CA"
  }, {
    path: "/en-CA/programs/:slug/edit",
    component: _0e06b77a,
    name: "programs-slug-edit___en-CA"
  }, {
    path: "/agency-codes/:slug",
    component: _ac4d7850,
    name: "agency-codes-slug___fr-CA"
  }, {
    path: "/banners/:slug",
    component: _8a44d20e,
    name: "banners-slug___fr-CA"
  }, {
    path: "/brokers/:slug",
    component: _011b2428,
    name: "brokers-slug___fr-CA"
  }, {
    path: "/contacts/:slug",
    component: _747803cd,
    name: "contacts-slug___fr-CA"
  }, {
    path: "/programs/:slug",
    component: _684f9de9,
    name: "programs-slug___fr-CA"
  }, {
    path: "/agency-codes/:slug?/edit",
    component: _e081f718,
    name: "agency-codes-slug-edit___fr-CA"
  }, {
    path: "/banners/:slug/edit",
    component: _6f434933,
    name: "banners-slug-edit___fr-CA"
  }, {
    path: "/brokers/:slug/edit",
    component: _77e37040,
    name: "brokers-slug-edit___fr-CA"
  }, {
    path: "/contacts/:slug/edit",
    component: _6cfdf1df,
    name: "contacts-slug-edit___fr-CA"
  }, {
    path: "/programs/:slug/edit",
    component: _0e06b77a,
    name: "programs-slug-edit___fr-CA"
  }, {
    path: "/",
    component: _482400f4,
    name: "index___fr-CA"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    showContent() {
      // Login callback is the only public page
      if (this.$route.path.includes('auth')) {
        return true;
      }

      return this.$isLoggedIn() && !this.$isTokenExpired();
    }
  }
};

const decimalRegex = /^\s*-?\d+(\.\d{1,2})?\s*$/;

export default {
  getMessage() {
    return 'Invalid decimal value';
  },
  validate(value) {
    if (value === null) {
      return true;
    }
    return decimalRegex.test(value);
  }
};

export default {
  setBrokersFilters(state, value) {
    state.brokersFilters = value;
  },
  setBrokersPagination(state, value) {
    state.brokersPagination = value;
  },
  setBrokersSort(state, value) {
    state.brokersSort = value;
  }
};

//
//
//
//
//
//
//
//
//

export default {
  props: ['error'],
  name: 'error'
};

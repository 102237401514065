import Countries from '@/models/countries';
import States from '@/models/states';

export default {
  countryOptions: () => i18n => {
    return [
      {
        text: i18n.t('global.form.placeholders.country'),
        value: null
      },
      ...Countries
    ];
  },
  stateOptions: () => i18n => {
    return [
      {
        text: i18n.t('global.form.placeholders.state'),
        value: null
      },
      ...States
    ];
  }
};

export default {
  setProgramsFilters({ commit }, value) {
    commit('setProgramsFilters', value);
  },
  setProgramsPagination({ commit }, value) {
    commit('setProgramsPagination', value);
  },
  setProgramsSort({ commit }, value) {
    commit('setProgramsSort', value);
  }
};

export const defaultState = {
  bannersFilters: {
    search: null,
    status: null,
    type: null
  },
  bannersPagination: {
    currentPage: 1,
    itemsPerPage: 10
  },
  bannersSort: {
    column: 'Name',
    desc: false
  }
};

const state = () => defaultState;

export default state;

export default {
  setBannersFilters(state, value) {
    state.bannersFilters = value;
  },
  setBannersPagination(state, value) {
    state.bannersPagination = value;
  },
  setBannersSort(state, value) {
    state.bannersSort = value;
  }
};

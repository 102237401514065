export default {
  setProgramsFilters(state, value) {
    state.programsFilters = value;
  },
  setProgramsPagination(state, value) {
    state.programsPagination = value;
  },
  setProgramsSort(state, value) {
    state.programsSort = value;
  }
};

export default {
  setBrokersFilters({ commit }, value) {
    commit('setBrokersFilters', value);
  },
  setBrokersPagination({ commit }, value) {
    commit('setBrokersPagination', value);
  },
  setBrokersSort({ commit }, value) {
    commit('setBrokersSort', value);
  }
};

export default {
  setContactsFilters(state, value) {
    state.contactsFilters = value;
  },
  setContactsPagination(state, value) {
    state.contactsPagination = value;
  },
  setContactsSort(state, value) {
    state.contactsSort = value;
  }
};

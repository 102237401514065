export default {
  setAgencyCodesFilters(state, value) {
    state.agencyCodesFilters = value;
  },
  setAgencyCodesPagination(state, value) {
    state.agencyCodesPagination = value;
  },
  setAgencyCodesSort(state, value) {
    state.agencyCodesSort = value;
  }
};
